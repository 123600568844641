<template>
  <div class="mt-2 container">
    <div class="col-12">
   
      <div class="row">
        <div class="col-lg-5">
          <div class="card">
            <div class="card-header">
              <i class="fa fa-refresh"></i> Homologación de Códigos
            </div>            
            <div class="card-body">     
              <div class="mt-1" style="overflow-y:scroll; height:550px;">
                  <select
                      class="form-select form-select-sm"                        
                      v-model="valorParametrica"
                      @change="listarParametricas()"
                    >
                      <option v-for="(item,index) in codigos" :key="index" :value="item.codigo">{{item.descripcion}}</option>
                  </select>             
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(item, index) in parametricas" :key="index">                  
                      <small class="text-primary">{{ item.descripcion }} </small>
                      <span class="badge bg-success rounded-pill">{{item.estado}}</span> 
                    </li>
                  </ul>                  
              </div>  
            </div>
          </div> 
        </div>
        <div class="col-lg-7">
          <div class="card">
            <div class="card-header">
              <i class="fa fa-cloud"></i> Interoperabilidad
            </div>            
            <div class="card-body">    
              
              <div class="alert" :class="{ 'alert-success': tokenActivo,  'alert-danger': !tokenActivo}">
                <h5 v-if="tokenActivo"><i class="fa fa-key fa-2x"></i> Token Activo *************</h5>
                <h5 v-else><i class="fa fa-times fa-2x"></i> Token Inactivo *************</h5>
                <p>Fecha Expiración: 06/04/2025 11:59:59 PM</p>
                <h5>{{ diasActivos()}}</h5>
                <p>
                  Sistema de Administración para la Pasarela de Pagos del Estado - PPE y el Sistema Único de Facturación del Estado - SUFE.
                </p>
                <p>Sistema Cliente: Facturación Salud</p>
                <p>Señor(a): JOSE LUIS DELGADO</p>

              </div>
            </div>
          </div> 
          <div class="card mt-2">
            <div class="card-header">
              <i class="fa fa-check"></i> Validación de Productos
            </div>            
            <div class="card-body">    
              
              <div class="table-responsive" style="overflow-y:scroll; height:300px;">
                <table class="table table-sm table-bordered table-condensed">
                  <thead>
                    <tr class="table-secondary">
                      <th class="text-center">Código SIN</th>
                      <th>Descripción</th>
                      <th>Actividad Económica</th>
                      <th>Descripción de Actividad</th>                      
                    </tr>
                  </thead>                          
                  <tbody>
                    <tr v-for="(item, index) in productos" :key="index">
                      <td class="text-center">{{ item.codigoSin}}</td>
                      <td>
                        {{item.descripcion}}                   
                      </td>
                      <td>
                        {{ item.actividadEconomica }}                 
                      </td>
                      <td>
                        {{item.descripcionActividad}}                   
                      </td>                   
                    </tr>
                  </tbody>
                </table>                        
              </div>

            </div>
          </div> 

        </div>
        
      </div>

    </div>
    <Loading v-show="cargando" />
  </div>   
</template>
<script>
import Loading from '@/components/Loading';
import moment from 'moment';

export default { 
  data() {
    return {
      cargando:false,
      valorParametrica: 'tipoDocumentoIdentidad',
      parametricas: [],
      codigos: [],
      productos: [],
      tokenActivo: false
    }
  },
  components: {
    Loading    
  },  
  methods: {
    async listarParametricas() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/parametricas/${this.valorParametrica}`);
        this.parametricas = resultados.data.contenido.registros.datos.filas; 
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async validacionProducto() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/validacionProductos`);
        this.productos = resultados.data.contenido.registros.datos.filas; 
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    diasActivos(){      
      
      let fechaExamen = moment('2025 04 06', "YYYY MM DD");      
      let hoy = moment();
      let diferencia = fechaExamen.diff(hoy,"days");
      if(diferencia<1){
        this.tokenActivo = false;
      }else{
        this.tokenActivo = true;
      }
      return `${diferencia} días restantes`;
    },
  },
  created(){
    this.codigos = [
      {'codigo': 'tipoDocumentoIdentidad', 'descripcion': 'Documento Identidad'},
      {'codigo': 'tipoDocumentoSector', 'descripcion': 'Documento Sector'},
      {'codigo': 'tipoEmision', 'descripcion': 'Tipo Emisión'},
      {'codigo': 'tipoFactura', 'descripcion': 'Tipo Factura'},
      {'codigo': 'tipoHabitacion', 'descripcion': 'Tipo Habitación'},
      {'codigo': 'tipoMetodoPago', 'descripcion': 'Método de Pago'},
      {'codigo': 'tipoMoneda', 'descripcion': 'Tipo Moneda'},
      {'codigo': 'tipoPuntoVenta', 'descripcion': 'Punto de Venta'},
      {'codigo': 'unidadMedida', 'descripcion': 'Unidad de Medida'}      
    ]
    this.listarParametricas();
    this.validacionProducto();
  }
}
</script>
